export const domain = () => {
  try {
    if (import.meta.env.VITE_DOMAIN_URL) {
      return import.meta.env.VITE_DOMAIN_URL;
    }
  } catch (error) {
    console.warn('Error getting VITE_DOMAIN_URL from vite', error);
  }

  try {
    return process?.env?.VITE_DOMAIN_URL;
  } catch (error) {
    console.error('Error getting VITE_DOMAIN_URL', error);
    throw error;
  }
}
